.sf-collection {
  margin-top: 1rem;

  > .form-group {
    padding-right: 7rem;
  }

  .sf-collection-remove-item, .sf-collection-remove-item:active,
  .sf-collection-item-sortable-handle, .sf-collection-item-sortable-handle:active {
    position: absolute;
    right: 1rem;
    top: 1rem;
    left: auto;
  }

  .sf-collection-item-sortable-handle + .sf-collection-remove-item,
  .sf-collection-remove-item + .sf-collection-item-sortable-handle {
    top: calc(32px + 2rem);
  }
}

td.sf-collection-action {
  width: 1%;
}